export default {
  complete: {
    price: 5900,
    label: 'Komplett',
  },
  premium: {
    price: 8900,
    label: 'Premium',
  },
  vip: {
    price: 39900,
    label: 'VIP',
  },
};
